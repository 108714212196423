import React, { useState, useEffect, useRef } from 'react'
import { Stack } from '@mui/material'
import LogoFrontPage from "../components/LogoFrontPage"
import CommunityPartners from "../components/CommunityPartners"
import titlePicture from '../pictures/titlepicture.jpg'
import '../style/LogoFrontPage.css'
import TextPicPar from '../components/TextPicPar'
import OurValues from '../components/OurValues'
import { getCurrentUser } from 'aws-amplify/auth';
import UploadPicComponent from "../components/UploadPictureComponent"
import '../style/cssanimations.css'

export default function AboutUs() {



    const [boxVisible1, setBoxVisible1] = useState(false);
    const boxRef1 = useRef()
    const [loggedInUser, loggedInUserSet] = useState(false)
    async function currentAuthenticatedUser() {
        try {
            const result = await getCurrentUser();
            if (result) loggedInUserSet(result)

        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.id === "ourteam") {
                    if (entry.isIntersecting) setBoxVisible1(true)
                }
            })
        })
        
        observer.observe(boxRef1.current)
        if (window.location.hash.includes('#who-are-we')) (document.getElementById('anchor')?.scrollIntoView({ behavior: "smooth", }))
        currentAuthenticatedUser()
    }, [])





    return (
        <Stack>
            <LogoFrontPage
                logo={(
                    <div style={{ textAlign: 'center' }}>
                        <div className='text'>ABOUT US</div>
                    </div>
                )}
                secondText={(<div>Changing disability services one person at a <br></br> time</div>)}
                aligns='left'
                loggedInUser={loggedInUser}
                pageName={'aboutus'}
            />

            <div id="anchor"></div>
            <TextPicPar

                picture={titlePicture}
                littleText={<div><div style={{ paddingBottom: '2%' }}>Abby Lou Services was started by parents that wanted to create a service provider who offered quality of care good enough for their daughter with down syndrome, Abby Lou.</div> <div>Abby Lou Services is now more than a program or a provider, it is a way of life. It is a culture where services are developed for, and tailored to, each individual. We are small and we plan to stay small because we believe it is in that personalized interaction that quality of care is grown and maintained.</div></div>}
                bigText={<div>WHO IS<br></br><span style={{ color: '#652C85' }}>ABBY</span><br />LOU?</div>}
                color={'#652C85'}
                pageName={'aboutus'}
                imageName={'whoisabbylou'}
            >
            </TextPicPar>

            <OurValues></OurValues>

            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '3%', }}>
                <div className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{ width: '70%', color: 'black', borderRadius: '3vmin', fontSize: '12vmin', fontWeight: '600', lineHeight: '90%', padding: '2vmin' }}>
                    OUR <span style={{ color: '#54AE49' }}>TEAM</span>
                </div>
                <div className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{ color: 'black', textAlign: 'left', fontSize: '2.5vmin', lineHeight: '120%', paddingBottom: '3%' }}>
                    Abby Lou Services staff come from all walks of life, with backgrounds from disability services, school districts, and marketing and sales to small businesses, public policy, and public safety.
                </div>

                <div  ref={boxRef1} id="ourteam">
                    <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/aboutus/ourteam.jpg`} className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        maxHeight: '100vh',
                        borderRadius: '4vmin',
                        animationDelay: '1000ms'
                    }} alt="abbyimage"
                    ></img>
                </div>
                <UploadPicComponent pageName={'aboutus'} imageName={'ourteam'}></UploadPicComponent>

            </div>

            <CommunityPartners />
        </Stack>
    )
}
