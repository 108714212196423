import React from 'react'
import '../style/Faq.css'
import '../style/Information.css'
import Faqs from '../components/Faqs.js'


export default function Information() {


    const faqData = [
        {
            headText: 'I’m a parent of a child with a disability, but I don’t really know where to start',
            footText: ['You can find free advice and assistance from the Utah Parent Center. Call for an appointment at (801) 272-1051 - they can walk you through guardianship, SS, DPSD, etc.',]
        },
        {
            headText: 'How do I obtain guardianship for a loved one or myself at 18?',
            footText: [
                'Option 1: Use an attorney. With the help of an attorney, complete the guardianship application, SS application, and Special Needs Trust all at the same time. We recommend Will Bangerter (435) 628-7004 - this is an attorney we know personally and he is familiar with the process. Let Will know that Abby Lou sent you. However, you may use any attorney who specializes in a related field.',
                'Option 2: Do it yourself. This is cheaper than an attorney, but you will need a Special Needs (SN) Trust, which can be difficult and time-consuming. At the very least, we recommend using an attorney to set up the SN Trust.',
            ]
        },
        {
            headText: 'How do I get everything set up with Social Security (SS) by myself?',
            footText: ['Call (800) 772-1213 for an appointment. After SS is in place, create a lease agreement for your child and have them pay rent. This ensures their benefits stay intact and increase the amount of money they can make. In order for SS to stay intact and not decrease, expenses must be demonstrated.',]
        },
        {
            headText: 'How do I get my loved one or myself on Medicaid with the Department of Workforce Services (DWS)?',
            footText: ['Call (866) 435-7414 to schedule an appointment and get on Medicaid ASAP. This is income-based, but when our children reach 18 years of age, their income is typically zero.',]
        },
        {
            headText: 'What is DSPD and how will it help my loved one or myself?',
            footText: [
                'In short, the Division of Services for People with Disabilities (DSPD) is part of the Utah Department of Health and Human Services (DHHS). DSPD promotes opportunities, and provides supports by overseeing home and community-based services for people with disabilities in order to lead self-determined lives.',
                <span>DSPD services are lifelong and the description of the services can be lengthy as there are many. However, the application for services can be found at: <a href='https://dspd.utah.gov'>https://dspd.utah.gov</a> by clicking the “Apply Online for Services” box. The process will guide you through lists of required paperwork for the application. Some of the paperwork includes: IEPs, Medicaid, psych evaluations, diagnoses, etc. The process will ask for income, but it is not an income-based program. DSPD funds applicants according to the urgency of the client and the environment in which they currently reside. With DSPD “the squeaky wheel gets the grease,” so we recommend that you call and email often to follow up on the application and where it is in the process.</span>
            ],
        },
        {
            headText: 'My loved one wants to work - how do we navigate that?',
            footText: [
                <span>We recommend that you work with Vocational Rehabilitation {'('}Voc Rehab{')'}. Voc Rehab can help with employment, adaptive needs, education, job coaching, and other services. Our contact, Joanie Weatherly, can be reached at (435) 673-5091, though you may wish to work with either Voc Rehab directly by visiting <a href="https://jobs.utah.gov/usor/">https://jobs.utah.gov/usor/</a> online or by visiting one of the offices in person.</span>,
            ],
        },
        {
            headText: 'I finally have services with DSPD. What now?',
            footText: [
                'The following are some of the steps we recommend once you have your DSPD funding in place:',
                '1) Choose a good support coordinator.',
                '2) Learn about different providers and maintain control and guardianship of your child and their funds.',
                '3) Learn about different day programs available in your area. All day programs are required to spend 75% of their time or more out in the community. Keep in mind, if the provider has a building for their program, they likely won’t be spending time in the community.', 
                '4) Understand that there are alternatives to the GROUP HOME model. The HOST HOME model (which we practice) is safer and promotes more independence by allowing for more Person-Centered Supports.',
                'Host homes are not for everyone. However, most providers do not offer it as an option because providers have less control and make less money. The compensation goes to the family that your child lives with and is used to improve their quality of life. That said, there is no perfect option, but we believe some are better than others.'
            ],
        },

        {
            headText: 'I’m not sure… But I would like to learn more about Host Homes and Abby Lou.',
            footText: [
                'Our goal, at the end of the day, is to ensure there are options out there for your and your loved one and that the standard for quality of care is raised beyond its current level. We encourage you to learn as much as you can and make an informed decision about where and in whose care you place your child.',
                'We welcome any questions, so please contact Jeremy or Lisa Creager if you have any additional questions or need ANY help. They can be reached at (702) 234-4604'
            ],
        },



    ]


    return (
        <div style={{ color: 'black', margin: '0px 3%' }}>
            <div style={{ fontWeight: '600', marginBottom: '3vmin' }}>Click the concern or question to find the connected resource</div>
            <div>
                
            <Faqs faqData={faqData} title={'INFORMATION'} />
        
            </div>
        </div>


    )
}