import React from 'react'
import { Stack } from '@mui/material'
import LogoFrontPage from "../components/LogoFrontPage"
import Faqs from "../components/Faqs"
import TextPicPar from '../components/TextPicPar'
import title from '../pictures/titlepicture.jpg'
import Benefits from '../components/Benefits'
import filler from '../pictures/comingsoon.jpg'
import '../style/LogoFrontPage.css'
import PictureCarousel from '../components/PictureCarousel'

export default function HostHomes() {

    const faqData = [
        {
            headText: 'How do Host Homes work?',
            footText: ["The supports offered by Host Home staff are customized to address the unique requirements of the individuals they serve. The extent of support, supervision, and active assistance provided is determined by the individual's specific needs. Some individuals are more self-sufficient and require less support, while others may need more assistance. Our goal is to match each individual with a Host Home Staff whose skills and support align best with their needs and abilities."],
        },
        {
            headText: 'Is this similar to "adopting" or “fostering” a child?',
            footText: ["While Abby Lou aims for individuals to fully integrate into the Host Home Staff's family dynamic, this is not akin to adopting a child. Host Home Staff members serve as mentors, offering a secure environment for individuals to develop, achieve their goals, and grow. Host Home Staff do not have legal obligations or authority over the individual. This commitment is not lifelong, but through our thorough matching process, we strive to ensure that both parties are well-acquainted and can coexist harmoniously. Some placements have been successful for years, while others may last a few months before either the Host Home Staff or the individual's needs change, leading to the end of the placement. However, in many instances, a lasting friendship and support system endure beyond the placement."],
        },
        {
            headText: "Who can become a Host Home Staff?",
            footText: ["Our Host Home Staff, who are at least 21 years of age, come from a range of backgrounds and have diverse skill levels. They may include stay-at-home moms, empty nesters looking for companionship and opportunities to give back, retirees, or individuals with backgrounds in healthcare and social services. Host Home Staff can be married or single, male or female, and represent various ethnicities and religious beliefs. However, what unites our Host Home Staff is their unwavering commitment to providing care and creating a positive influence in someone's life."],
        },
        {
            headText: "Why don't families take care of their sons/daughters instead of sending them to a host home?",
            footText: ["Host homes create an opportunity for parents and children with disabilities to develop a more typical adult relationship rather than the parents being the caretaker.  It also allows people with disabilities to be more independent while still providing the necessary support for each individual to be successful. Additionally, as parents age it becomes more difficult to provide social opportunities and experiences"],
        },
        {
            headText: "What responsibilities do Host Home Staff have in providing and purchasing for the individual living with them?",
            footText: ["Host Home Staff typically take on the responsibility of providing nutritious meals and snacks, basic personal care items like shampoo, soap, and toothpaste, clothing, bedding, and bedroom furnishings for the individual in their care. Host Home Staff receive a stipend to assist with the cost of care. The situation varies from case to case, as some individuals may arrive with their own bedroom furnishings and clothing, while others may have minimal belongings. Additionally, Host Home Staff are in charge of arranging transportation to and from appointments, organizing recreational activities after day program hours, managing housing utilities, and maintaining the property."],
        },
        {
            headText: "Do Host Home Staff have the ability to take vacations?",
            footText: ["We understand the importance of taking breaks and that life can be unpredictable. We also recognize the value of family time and encourage you to enjoy it. If you plan to go on vacation, individuals under your care can accompany you, or alternative arrangements can be made if they prefer not to travel with you. Abby Lou management will maintain a close relationship with you to anticipate and plan for your respite support needs whenever possible. We have staff members ready to provide temporary care or respite support, individuals of your choosing who can step in after undergoing a background check, or arrangements can be made for the individual to stay with their family members if applicable and appropriate. In case of emergencies or crises, our team will be available to facilitate necessary arrangements."],
        },
        {
            headText: "What steps and qualifications are necessary to become a Host Home Staff member? What requirements and certifications do I need to fulfill?",
            footText: ["The initial step is determining if this role aligns with your interests and capabilities. Contact Jeremy Creager for more details on becoming a Host Home Staff member. The second step involves obtaining live scan fingerprints and undergoing a background check conducted by DACs. Next, connect with Lisa Creager to arrange a volunteering opportunity at the day program, enabling you to interact with the individuals we support and gain insights into disability care before committing to the Host Home Staff role. Additionally, mandatory trainings and certifications such as CPR/First Aid, Food Handlers Permit, Host Home Inspection, car insurance coverage of at least $100/300, driving record verification, and completion of general training video courses are required. Upon confirming a placement in your home, further training tailored to individualized care will be necessary."],
        },
    ]

    return (
        <Stack>
            <LogoFrontPage
                logo={(
                    <div>HOST<br></br>HOMES</div>
                )}
                secondText={(
                    <div><em>Home</em> is the best way to describe it</div>
                )}
                aligns="right"
                pageName={'hosthomes'}
            />

            <TextPicPar
                picture={title}
                littleText={<div><div style={{ paddingBottom: '2%' }}>When we start looking for residential care options for those we love with disabilities, we often feel our only options are group homes or facilities. While these options suit some needs, there is another option - host homes.</div> <div>With our host homes, our loved ones are able to live in a home environment, often in families, and with trained staff who support and take care of needs.</div></div>}
                bigText={<div style={{ wordWrap: 'break-word' }}>ONE<br></br><span style={{ color: '#0871B9' }}>MORE</span><br />OPTION</div>}
                color={'#0871B9'}
                reverse={true}
                pageName={'hosthomes'}
                imageName={'onemoreoption'}
            >
            </TextPicPar>

            <Benefits></Benefits>

            <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', display: 'flex', marginBottom: '-1%' }}>
                <div style={{ width: '70%', color: 'black', borderRadius: '3vmin', fontSize: '8vmin', fontWeight: '600', lineHeight: '90%', padding: '3%' }}>
                    AVAILABLE <br />HOST <span style={{ color: '#EA2225' }}>HOMES</span>
                </div>
            </div>
            <PictureCarousel pageName={'hosthomes'}  fillType={'contain'}></PictureCarousel>



            <div style={{ margin: '12vmin 3% 10vmin 3%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                <div style={{ fontSize: '10vmin', fontWeight: '800', lineHeight: '100%', padding: '3%' }}>
                    A <span style={{ color: '#652C85' }}>DAY</span> IN <br></br>THE LIFE
                </div>

                <div style={{width: '100%', height: '80vmin', display: 'flex', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center'}}>
                    <img style={{
                        width: 'auto',
                        height: '25%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',

                    }} src={filler} alt='Meaningful text'></img>
                </div>
            </div>
            <Faqs faqData={faqData} />
        </Stack>
    )
}
