
import React, { useEffect, useRef, useState } from 'react';
import UploadPicComponent from './UploadPictureComponent';

export default function LogoFrontPage(props) {

    const [myElementIsVisible, setMyElementIsVisible] = useState(false);
    const [hamburgerOpen] = React.useState(false)
    const [hamburgerIconClassState, sethamburgerIconClassState] = React.useState('hamInitialHidden')
    const [hamHide, setHamHide] = React.useState(true)
    const [smallScreen, setSmallScreen] = React.useState(false)

    const myRef = useRef()
    const bigTextRef = useRef()
    const inputRef = useRef()





    useEffect(() => {
        function iconCheck() {
            if (smallScreen) {
                sethamburgerIconClassState('hamPopOut')
            }
            else {
                if ((!hamHide && !hamburgerOpen)) sethamburgerIconClassState('hamPopOut')
                else {
                    if (hamburgerIconClassState !== 'hamInitialHidden') sethamburgerIconClassState('hamPopOutExit')
                }
            }
        }

        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.className === "SecondBorder") setMyElementIsVisible(entry.isIntersecting)
                if (entry.target.className === "LogoBorder") setHamHide(entry.isIntersecting)
            })
        })

        let sizeObserver = new ResizeObserver((entries) => {
            const x = entries[0]
            const isSmall = x.contentRect.width < 1050
            setSmallScreen(isSmall)
        })
        observer.observe(myRef.current)
        observer.observe(bigTextRef.current)
        sizeObserver.observe(inputRef.current)
        iconCheck()
    }, [hamHide, hamburgerOpen, hamburgerIconClassState, smallScreen])


    const {
        logo,
        secondText,
        pageName,
    } = props

    return (
        <div >
            <div ref={inputRef} style={{  backgroundImage: `url(${process.env.REACT_APP_S3_BUCKET_URL}/public/${pageName}/titlepicture.jpg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>

                <div ref={bigTextRef} className='LogoBorder'>
                    {logo}
                </div>

                <UploadPicComponent pageName={pageName} imageName={'titlepicture'}></UploadPicComponent>
            </div>
            <div>
                <div className={'SecondBorder'} ref={myRef} >
                    {myElementIsVisible ? <div className={'textFadeIn'}>
                        {secondText}
                    </div> : <div />}
                </div>
            </div>

        </div>


    )
}