
import './App.css';
import HomePage from './pages/HomePage'
import HostHomes from './pages/HostHomes'
import DayPrograms from './pages/DayPrograms';
import NavBar from './components/NavBar'
import AboutUs from './pages/AboutUs'
import Calendar from './pages/Calendar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MyFooter from './components/MyFooter'
import Resources from './pages/Resources';
import Gallery from './pages/Gallery'
import LoginPage from './pages/LoginPage';
import React from 'react';
import { UserContextProvider } from './UserContext';


function MyRoutes() {
  return (
    <div>

      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route index element={
            <HomePage />
          } />
          <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
          <Route path="/host-homes" element={<HostHomes />}></Route>
          <Route path="/day-programs" element={<DayPrograms />}></Route>
          <Route path="/resources" element={<Resources />}></Route>
          <Route path="/calendar" element={<Calendar />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/abbylogin" element={<LoginPage />}></Route>
        </Route>

      </Routes>
      <MyFooter></MyFooter>

    </div>


  )
}



function App() {



  return (
    <BrowserRouter>

      <div className="App">
        <head>
          <meta name="keywords" content="React"></meta>
          <meta name="description" content="Company for Special needs in St George. St. George Special needs care."></meta>
        </head>
        <header className="App-header">
        </header>
        <body style={{ height: '100%' }} className="App-body">
          <UserContextProvider>
            <MyRoutes></MyRoutes>
          </UserContextProvider>

        </body>
      </div>
    </BrowserRouter>
  );
}

export default App;
