import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import '../style/Calendar.css'
import LogoFrontPage from '../components/LogoFrontPage'
import TextPicPar from '../components/TextPicPar'
import titlePicture from '../pictures/titlepicture.jpg'


export default function Calendar() {

    useEffect(() => {
        if(window.location.hash.includes('#calendar-anchor')) (document.getElementById('calendar-anchor').scrollIntoView({ behavior: "smooth",}))
    })

    return (
        <Stack>
            <LogoFrontPage
                logo={(
                    <div style={{ textAlign: 'center' }}>
                        <div className='text'>CALENDAR</div>
                    </div>
                )}
                
                secondText={(<div>There’s no party like an Abby Lou party - we’ll show you</div>)}
                aligns='left'
                pageName={'calendar'}
            />

            <TextPicPar
                picture={titlePicture}
                littleText={<div><div style={{ paddingBottom: '2%' }}>We do a lot. Outside of day program we have performances at benefit concerts, we put on annual events, and we attend community events. We love having visitors and engaging with people in the community.</div></div>}
                bigText={<div>HANG<br></br><span style={{color: '#652C85'}}>WITH</span><br />US</div>}
                color={'#652C85'}
                pageName={'calendar'}
                imageName={'hangwithus'}
            >
            </TextPicPar>
            <div  style={{ fontWeight: '600', color: 'black', margin: '-5% 0% 5% 0%' }}>Below is a calendar of events, feel free to check it out and come visit!</div>
            <div style={{display: 'flex', margin: '0px 3%', flexDirection: 'column', alignContent:'center', justifyContent: 'center', alignItems: 'center', paddingBottom: '10vmin',}}>
                <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', borderRadius: '2vmin', width: '100%'}}>
                    <div id="calendar-anchor"  style={{display: 'flex', alignContent: 'center', justifyContent: 'center', textAlign: 'center', alignItems: 'center', fontSize: '10vmin', color: 'black', fontWeight: '700' }}>CALENDAR</div>
                </div>
                <iframe  title="calendar" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&src=am9uYXRoYW4ubW9yZ2FuLmNvbXB1dGVyQGdtYWlsLmNvbQ&src=OTNkZTg3ODdmYzVmZGYzYTRjYzA4ODRmMzUxZGMzMWQ4MDZkYTY1Y2QwNjA5ODIyNzVkMDlhNjgxNDQ2ZjVkMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%23E67C73&color=%2333B679&color=%230B8043"
                    style={{ border: 'solid 0px #777', frameborder: '0', scrolling: "no", height: '80vmin', width: '100%'}} ></iframe>

            </div>
        </Stack>
    )
}
