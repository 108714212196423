import React, { useState, useRef, useEffect } from 'react'
import UploadPicComponent from './UploadPictureComponent'
import '../style/cssanimations.css'


export default function LogoFrontPage(props) {
    const {
        bigText,
        littleText,
        reverse = false,
        pageName,
        imageName,
    } = props



   const [boxVisible, setBoxVisible] = useState(false);
   const boxRef = useRef()


   useEffect(() => {
       let observer = new IntersectionObserver((entries) => {
           entries.forEach((entry) => {
               if (entry.target.id === "image1") {
                   if(entry.isIntersecting) setBoxVisible(true)
               }
           })
       })
       observer.observe(boxRef.current)
   }, [])




    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>

        <div style={{ display: 'flex', flexDirection: 'column', margin: '0% 3% 10vmin 3%', maxWidth:'1750px'}}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>
                {reverse && <div className= {boxVisible ? 'fadeInDownward': 'restingPosition'} style={{ width: '50%', marginRight: '2%', }}>
                <div style={{position: 'absolute'}}> <UploadPicComponent pageName={pageName} imageName={imageName}></UploadPicComponent></div>
                    <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${pageName}/${imageName}.jpg`} style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4vmin',
                    }}
                    alt="pictures"
                    ></img>
                
                </div>}

                <div ref={boxRef} id="image1" className= {boxVisible ? 'fadeInDownward': 'restingPosition'} style={{
                    lineHeight: '95%',
                    width: '50%',
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: reverse ? 'left' : 'right',
                    justifyContent: 'center',
                    fontSize: '12vmin',
                    fontWeight: '700',
                    padding: '2%',
                    borderRadius: '4vmin',
                    wordBreak: 'break-word'
                }}>
                    {bigText}
                </div>
                {!reverse && <div className= {boxVisible ? 'fadeInDownward': ''} style={{ width: '50%', marginLeft: '2%', }}>
                    <div style={{position: 'absolute'}}>  <UploadPicComponent pageName={pageName} imageName={imageName}></UploadPicComponent></div>
                    <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${pageName}/${imageName}.jpg`} style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4vmin'
                    }}
                    alt="pictures"
                    ></img>
                     
                </div>}


            </div>
            <div className= {boxVisible ? 'fadeInDownward': 'restingPosition'} style={{ animationDelay: '300ms', padding: '2% 0%', textAlign: 'left', color: 'black', fontSize: '2.5vmin', lineHeight: '110%' }}>

                {littleText}
            </div>
        </div>
            
        </div >
    )
}