import React, { useEffect, useRef } from 'react';
import AbbyTextPurple from './AbbyTextPurplee';
import CloseSVG from '../pictures/svg/CloseButton.svg'
import { NavLink } from 'react-router-dom';
import '../style/navBar.css'


export default function PopoutNavBar(props) {

    const { hamburgerOpen, hamburgerClose } = props
    const myRef = useRef()

    const closeHelper = (() => {
        window.scrollTo({
            top: 0,

        })
        hamburgerClose()
    })



    useEffect(() => {

        function handleClickOutside(event) {
            if (myRef.current && !myRef.current.contains(event.target)) {
                hamburgerClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myRef, hamburgerClose])


    return (


        <div className={hamburgerOpen ? 'popOutNav' : 'popOutNavExit'} ref={myRef}>
            <div style={{ height: '100vh', width: '70vw', position: 'fixed', backgroundColor: '#1B1B1B', left: '31vw', top: '0'}}>
                <div style={{ height: '10%', width: 'auto', display: 'flex', justifyContent: 'space-between', padding: '5%', flexDirection: 'column' }}>
                    <div style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-between' }}>
                        <AbbyTextPurple></AbbyTextPurple>
                        <button onClick={hamburgerClose} style={{ borderStyle: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}><img draggable='false' style={{width: '10vmin', paddingRight: '3vmin'}} src={CloseSVG}></img></button>
                    </div>


                    <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', paddingTop: '15%', justifyContent: 'revert', textAlign: 'left ' }}>
                        <div style={{ paddingRight: '10%' }}>
                            <dl className='footerList'>
                                <dt>Abby Lou</dt>
                                <dd><NavLink to='/' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Home</NavLink></dd>
                                <dd><NavLink to='/about-us' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>About Us</NavLink></dd>
                            </dl>
                        </div>

                        <div>
                            <dl className='footerList'>
                                <dt>Services</dt>
                                <dd><NavLink to='/day-programs' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Day Program</NavLink></dd>
                                <dd><NavLink to='/host-homes' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Host Homes</NavLink></dd>
                            </dl>
                        </div>
                    </div>

                    <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', paddingTop: '15%', justifyContent: 'revert', textAlign: 'left ' }}>
                        <div style={{ paddingRight: '10%' }}>
                            <dl className='footerList'>
                                <dt>Meet Us</dt>
                                <dd><NavLink to='/calendar' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Calendar</NavLink></dd>
                                <dd><NavLink to='/gallery' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Gallery</NavLink></dd>
                            </dl>
                        </div>

                        <div>
                            <dl className='footerList'>
                                <dt>Get Info</dt>
                                <dd><NavLink to='/resources' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Resources</NavLink></dd>

                            </dl>
                        </div>
                    </div>


                </div>
            </div>
        </div>





    );
};


