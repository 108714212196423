import React from 'react'
import { Stack } from '@mui/material'
import LogoFrontPage from "../components/LogoFrontPage"
import TextPicPar from '../components/TextPicPar'
import titlePicture from '../pictures/titlepicture.jpg'
import Information from '../components/Information'
import '../style/LogoFrontPage.css'

export default function Resources() {
    return (
        <Stack>
            <LogoFrontPage
                aligns="right"
                logo={(
                    <div>RESOURCES</div>
                )}
                secondText={(
                    <div>We got you</div>
                )}
                pageName={'resources'}

            />

            <TextPicPar
                picture={titlePicture}
                littleText={<div><div style={{ paddingBottom: '2%' }}>There’s no question that navigating the world of disability care can be daunting. Good news - even if you don’t choose to work with us, we still want to provide you with some helpful resources in your journey.</div>Below is a series of concerns or questions we know come up often. We found out the hard way so you don’t have to.</div>}
                bigText={<div>LET <span style={{color: '#0871B9'}}>US</span><br></br>HELP</div>}
                color={'#0871B9'}
                reverse={true}
                pageName={'resources'}
                imageName={'letushelp'}
            >
            </TextPicPar>


            <Information></Information>
        </Stack>
    )
}