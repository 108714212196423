import React, { useState, useEffect } from 'react'
import '../style/IconHover.css'
import MyButton from './MyButton'
import { NavLink } from 'react-router-dom'




export default function IconHover(props) {

    const {
        text,
        color = 'purple',
        svg,
        link
    } = props

    const [box, setBox] = useState(false);

    const mouseOver = (() => {
        setBox(true)
    })

    const mouseOut = (() => {
        setBox(false)
    })

    useEffect(() => {

    }, [])



    return (
        <div className="iconHoverBox" onMouseOver={mouseOver} onMouseLeave={mouseOut} style={{ backgroundColor: box ? color : '#FEFEFE', padding: '2vmin', margin: '5vmin', borderRadius: '2vmin', justifyContent: 'center',  alignContent: 'center', display: 'flex', flexDirection: 'column', minWidth: '300px', width: '40vmin' }}>
            <div><img draggable='false' alt="iconHoverButton" className={box ? 'filter-white' : ''} style={{ width: '80%' }} src={svg}></img></div>
            <div style={{ lineHeight: '90%', color: box ? '#FEFEFE' : 'black', fontWeight: '500' }}>{text}</div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: '10px' }}> <NavLink to={`/${link}`} onClick={(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'

                })
            })}><MyButton textcolor={box ? color : '#FEFEFE'} text={'Explore'}></MyButton></NavLink></div>
        </div>
    )
}