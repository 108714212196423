import React, { useState } from 'react'

import '../style/OurValues.css'


export default function AbbyTextPurple() {

    function SmallBall(props) {
        const {
            scolor,
            top,
            right,
            secondColorBool,


        } = props

        return (

            < div className='ourValuesBackgroundChangeEffect' style={{
                position: 'relative',
                backgroundColor: secondColorBool ? scolor : '#FDFDFD',
                borderColor: scolor,
                width: '3.2vmin',
                height: '3.2vmin',
                borderRadius: '5000px',
                top: top,
                right: right,
                zIndex: '2'
            }
            } ></div >
        )

    }

    function BigBall(props) {
        const {
            color,
            scolor,
            top,
            right,
            transformDeg = 0,
            colorBool,
            secondColorBool,
        } = props

        return (

            < div className='ourValuesBackgroundChangeEffect' style={{
                transform: `rotate(${transformDeg}deg)`,
                position: 'relative',
                backgroundColor: colorBool ? color : '#FDFDFD',
                borderColor: color,
                width: '10vmin',
                height: '10vmin',
                borderRadius: '100%',
                top: top,
                right: right,
            }}>
                {SmallBall({ scolor: scolor, top: '-1.02vmin', right: '1.5vmin', secondColorBool: secondColorBool})}
                {SmallBall({ scolor: scolor, top: '-4.2vmin', right: '-8.2vmin', secondColorBool: secondColorBool})}
                <div className='ourValuesBackgroundChangeEffect' style={{
                    position: 'relative',
                    width: '10vmin',
                    height: '4vmin',
                    backgroundColor: colorBool ? color : '#FDFDFD',
                    top: '-9vmin',
                    right: '0px',
                    zIndex: '1'
                }}></div>
            </div >
        )
    }

    const [greenHover, greenHoverSet] = useState(false)
    const [blueHover, blueHoverSet] = useState(false)
    const [redHover, redHoverSet] = useState(false)
    const [purpleHover, purpleHoverSet] = useState(false)

    const greenHelperEnter = (() => { greenHoverSet(true) })
    const blueHelperEnter = (() => { blueHoverSet(true) })
    const redHelperEnter = (() => { redHoverSet(true) })
    const purpleHelperEnter = (() => { purpleHoverSet(true) })

    return (

        <div style={{ color: 'Black', fontSize: '4vmin', fontWeight: '600', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ fontSize: '2vmin', padding: '4vmin 0px 2vmin 0px' }}>Scroll over the value to learn more</div>
            <div style={{ fontSize: '12vmin' }}>OUR <span style={{color:'#0871B9'}}>VALUES</span></div>
            <div style={{ width: '80vmin', height: '80vmin', position: 'static', borderRadius: '10%', fontSize: '4vmin' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ color: 'white', width: '50%', height: '50%', position: 'relative' }}>


                        <div onMouseEnter={greenHelperEnter}  className='ourValuesBackgroundChangeEffect' style={{ width: '40vmin', height: '40vmin', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', backgroundColor: greenHover ? '#54AE49' : '#FDFDFD', borderRadius: '2vmin 0px 0px 0px' }}>
                            <div className={!greenHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ color: 'black', fontSize: '4vmin' }}>FAMILY</div>
                            <div className={greenHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'}   >FAMILY</div>
                            <div className={greenHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'}  style={{ width: '40%', fontSize: '2vmin', fontWeight: '400' }}>
                                Prioritizing genuine love, connections, and relationships
                            </div>

                        </div>
                        <div onMouseEnter={redHelperEnter}  className='ourValuesBackgroundChangeEffect' style={{ width: '100%', height: '40vmin', display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: redHover ? '#EA2225' : '#FDFDFD', borderRadius: '0px 0px 0px 2vmin' }}>
                            <div className={!redHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ color: 'black', fontSize: '4vmin' }}>COMMUNITY</div>
                            <div className={redHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'}>COMMUNITY</div>
                            <div className={redHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ width: '40%', fontSize: '2vmin', fontWeight: '400' }}>
                                Creating a sense of community in and outside the home
                            </div>
                        </div>


                    </div>


                    <div style={{ color: 'white', width: '50%', height: '50%', position: 'relative' }}>


                        <div onMouseEnter={blueHelperEnter} className='ourValuesBackgroundChangeEffect' style={{ width: '100%', height: '40vmin', display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: blueHover ? '#0871B9' : '#FDFDFD', borderRadius: '0px 2vmin 0px 0px ' }}>
                            <div className={!blueHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ color: 'black', fontSize: '4vmin' }}>QUALITY <br></br> OF CARE</div>
                            <div className={blueHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'}>QUALITY OF CARE</div>
                            <div className={blueHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ width: '40%', fontSize: '2vmin', fontWeight: '400' }}>
                                Services that are tailored to each individual’s specific needs
                            </div>
                        </div>



                        <div onMouseEnter={purpleHelperEnter} className='ourValuesBackgroundChangeEffect' style={{ width: '100%', height: '40vmin', display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: purpleHover ? '#652C85' : '#FDFDFD', borderRadius: '0px 0px 2vmin 0px' }}>
                            <div className={!purpleHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ color: 'black', fontSize: '4vmin' }}>RESPECT</div>
                            <div  className={purpleHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'}>RESPECT</div>
                            <div  className={purpleHover ? 'valuesSlowTextAppear' : 'valuesSlowDisappear'} style={{ width: '40%', fontSize: '2vmin', fontWeight: '400' }}>
                                Dignity and respects guides our interactions and goals
                            </div>
                        </div>


                    </div>

                </div>

            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* green */}
                    {BigBall({ color: '#54AE49', scolor: '#EA2225', top: '-39vmin', right: '5vmin', colorBool: greenHover, secondColorBool: redHover})}
                    {/*Red  */}
                    {BigBall({ color: '#EA2225', scolor: '#652C85', top: '-25vmin', right: '-11vmin', transformDeg: '-90', colorBool: redHover, secondColorBool: purpleHover })}
                    {/* Purple */}
                    {BigBall({ color: '#652C85', scolor: '#0871B9', top: '-51vmin', right: '-15vmin', transformDeg: '180',  colorBool: purpleHover, secondColorBool: blueHover})}
                    {/* blue */}
                    {BigBall({ color: '#0871B9', scolor: '#54AE49', top: '-64vmin', right: '21vmin', transformDeg: '90', colorBool: blueHover, secondColorBool: greenHover })}
                </div>
            </div>
        </div>

    )
}