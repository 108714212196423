import React, { useEffect, useRef } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { List, ListItemButton, Stack, } from '@mui/material';
import HamSVG from '../pictures/svg/HamburgerSVG.svg'
import PopoutNavBar from './PopoutNavBar';
import $ from 'jquery'
import '../style/navBar.css'
import '../style/cssanimations.css'

export default function App() {
    const [hamburgerOpen, setHamburgerOpen] = React.useState(false)
    const [hamburgerInitial, setHamburgerInitial] = React.useState(false)
    const [hamburgerIconClassState, sethamburgerIconClassState] = React.useState('hamInitialHidden')
    const [hamHide, setHamHide] = React.useState(true)
    const [smallScreen, setSmallScreen] = React.useState(false)
    const [pageReset, setPageReset] = React.useState(true)
    const [setIndex, setIndexSet] = React.useState(0)
    const inputRef = useRef()
    const divRef = useRef()

    const pages = [
        { page: '/', pageName: 'HOMEPAGE', delay: '500ms' },
        { page: '/about-us', pageName: 'ABOUT US', delay: '600ms' },
        { page: '/day-programs', pageName: 'DAY PROGRAM', delay: '700ms' },
        { page: '/host-homes', pageName: 'HOST HOMES', delay: '800ms' },
        { page: '/calendar', pageName: 'CALENDAR', delay: '1000ms' },
        { page: '/gallery', pageName: 'GALLERY', delay: '1100ms' },
        { page: '/resources', pageName: 'RESOURCES', delay: '1200ms' },
    ]
    function resettingPage() {
        setPageReset(prevState => !prevState)
    }
    function lockScroll(e) { return false }
    function hamburgerClick() {
        if (hamburgerInitial === false) setHamburgerInitial(true)
        setHamburgerOpen(!hamburgerOpen)
        $('body').on('scroll mousewheel touchmove', lockScroll);
    }
    function hamburgerClose() {
        setHamburgerOpen(false)
        $('body').off('scroll mousewheel touchmove');
    }
    useEffect(() => {
        function iconCheck() {
            if (smallScreen) {
                sethamburgerIconClassState('hamPopOut')
            }
            else {
                if ((!hamHide && !hamburgerOpen)) sethamburgerIconClassState('hamPopOut')
                else {
                    if (hamburgerIconClassState !== 'hamInitialHidden') sethamburgerIconClassState('hamPopOutExit')
                }
            }


        }
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.className === "navBarShown") {
                    setHamHide(entry.isIntersecting)
                }
            })
        })
        let sizeObserver = new ResizeObserver((entries) => {
            const x = entries[0]
            const isSmall = x.contentRect.width < 1050
            setSmallScreen(isSmall)
        })
        sizeObserver.observe(inputRef.current)
        observer.observe(divRef.current)
        iconCheck()
    }, [hamHide, hamburgerOpen, hamburgerIconClassState, smallScreen])

    return (
        <div ref={inputRef} style={{ maxWidth: '100vw', overflow: 'hidden' }}>
            <div className='navBarShown' ref={divRef} >
                <div style={{ position: 'absolute', width: '100%', }}>
                    {!smallScreen &&
                        <div className={pageReset ? 'fadeInDownward' : 'repeat'} style={{ backgroundColor: 'rgb(27, 27, 27, 0.6)' } }>
                          
                                <List className='navAnimation' component={Stack} style={{ wordBreak: 'break-word', margin: '0px', padding: '0px', fontSize: '25px', color: 'white', flexDirection: 'row', }} direction="row">
                                    {pages.map((page, i) => {
                                        return (
                                            <ListItemButton className={pageReset ? 'fadeInDownward' : 'repeat'} onClick={() => {
                                                if (setIndex !== i) {
                                                    setIndexSet(i)
                                                    resettingPage()
                                                }
                                            }} component={NavLink} sx={{
                                                  ":hover": {
                                                    backgroundColor: "rgb(40, 40, 40)"
                                                  },                                display: 'flex', justifyContent: 'center', animationDelay: `${(Math.abs(setIndex - i) * 100) + 500}ms` }} to={page.page} >{page.pageName}</ListItemButton>
                                    )
                                    })}
                                </List>
                    
                        </div>
                    }

                </div>
                <button className={hamburgerIconClassState} onClick={hamburgerClick} style={{ position: 'fixed', borderStyle: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}><img style={{ width: '10vmin' }} src={HamSVG} draggable='false'></img></button>

                <div>
                    {hamburgerInitial && <PopoutNavBar hamburgerOpen={hamburgerOpen} hamburgerClose={hamburgerClose} lockScroll={lockScroll}></PopoutNavBar>}
                </div>

            </div>
            <Outlet />
        </div>
    );
};


