import { uploadData } from 'aws-amplify/storage';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';

export default function UploadPicComponent(props) {
    const [userContextBool, setUserContextBool] = useState(false)
    const {
        pageName,
        imageName
    } = props

    const context = useContext(UserContext)

    useEffect(() => {
        setUserContextBool(context)
    }, [context])


    const [uploadPhoto, uploadPhotoSet] = useState({ value: '' })
    const uploadphoto = (async (e) => {
        try {
            const result = await uploadData({
                key: `${pageName}/${imageName}.jpg`,
                data: uploadPhoto
            }).result;
            window.location.reload();
        } catch (error) {
            console.log('Error : ', error);
        }

    })

    return (
        <div>
        
                { context && (
                    <div style={{color: 'black'}}>
                        <input type="file" accept="image/jpeg" onChange={(e) => uploadPhotoSet(e.target.files[0])} />
                        <button onClick={uploadphoto}>Save</button>
                    </div>
                )}
            
        </div>
    )


}

