import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'

import '@aws-amplify/ui-react/styles.css';
import config from '../amplifyconfiguration.json';

Amplify.configure(config);
function App({ signOut, user }) {
  const navigate = useNavigate()

  const SignOutHelper = (() => {
    signOut()
    navigate('/')
    

  })



  return (
    <>
      <div style={{color: 'black'}}>
        
        <h1>Hello {user.username}</h1>
        <button onClick={SignOutHelper}>Sign out</button>

      </div>
    </>
  );
}

export default withAuthenticator(App, { hideSignUp: true });