import React, { useEffect } from 'react'
import '../style/WhoAreWe.css'
import AbbyTextPurple from './AbbyTextPurplee';
import facebookSVG from '../pictures/svg/facebookSVG.svg'
import instaSVG from '../pictures/svg/instaSVG.svg'
import youtubeSVG from '../pictures/svg/youtubeSVG.svg'
import linkdinSVG from '../pictures/svg/linkdinPNG.png'
import { NavLink } from 'react-router-dom';

export default function MyFooter(props) {

    const closeHelper = (() => {
        window.scrollTo({
            top: 0,

        })
    })

    useEffect(() => {

    })

    return (


        <div style={{ width: '100%', backgroundColor: '#1b1b1b', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{ margin: '0 2% 4% 2%' }}>
                <div style={{ height: '30%', padding: '5vh 0px', display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'left' }}>
                    <AbbyTextPurple />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'left', fontSize: '2vmin', fontWeight: '600', alignItems: 'flex-start' }}>
                    <div style={{ width: '40%', wordWrap: '-moz-initial' }}>
                        <div>
                            Follow us
                        </div>
                        <div style={{display:'flex', flexDirection: 'row', paddingTop: '10px'}}>
                            <a  target="_blank" rel="noreferrer" style={{width: '10%', marginRight: '5%'}} href='https://www.facebook.com/profile.php?id=61554158736376'><img style={{width: '100%'}} className="svgimage" src={facebookSVG} alt='facebook'></img></a>
                            <a  target="_blank" rel="noreferrer" style={{width: '10%', marginRight: '5%'}} href='https://www.instagram.com/abbylouservices/'><img style={{width: '100%'}}  className="svgimage" src={instaSVG} alt='instagram'></img></a>
                            <a  target="_blank" rel="noreferrer" style={{width: '10%', marginRight: '5%'}} href='https://www.youtube.com'><img style={{width: '100%'}} className="svgimage" src={youtubeSVG} alt='youtube'></img></a>
                            <a  target="_blank" rel="noreferrer" style={{width: '10%', marginRight: '5%'}} href='https://www.linkedin.com/company/99302437/admin/feed/posts/?feedType=following'><img style={{width: '100%'}} className="svgimage" src={linkdinSVG} alt='linkdin'></img></a>
                        </div>
                    </div>
                    <div style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <dl className='footerList'>
                                <dt>Abby Lou</dt>
                                <dd><NavLink to='/' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Home</NavLink></dd>
                                <dd><NavLink to='/about-us' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>About Us</NavLink></dd>
                            </dl>
                        </div>

                        <div>
                            <dl className='footerList'>
                                <dt>Services</dt>
                                <dd><NavLink to='/day-programs' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Day Program</NavLink></dd>
                                <dd><NavLink to='/host-homes' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Host Homes</NavLink></dd>
                            </dl>
                        </div>

                        <div>
                            <dl className='footerList'>
                                <dt>Meet Us</dt>
                                <dd><NavLink to='/calendar' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Calendar</NavLink></dd>
                                <dd><NavLink to='/gallery' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Gallery</NavLink></dd>
                            </dl>
                        </div>

                        <div>
                            <dl className='footerList'>
                                <dt>Get Info</dt>
                                <dd><NavLink to='/resources' className="onHover" style={{ textDecoration: 'none'}} onClick={closeHelper}>Resources</NavLink></dd>
                            </dl>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    )
}