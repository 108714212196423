import React from 'react'

export default function AbbyTextPurple() {
    return (

        <div style={{ color: 'white', fontSize: '4vmin', fontWeight: '600', textAlign: 'center', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
            ABBY<span style={{ color: '#682c84' }}>LOU</span>SERVICES
        </div>

    )
}