import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/PictureCarousel.css"
import { list } from 'aws-amplify/storage';
import { UserContext } from '../UserContext';
import { TiDelete } from "react-icons/ti";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { remove, uploadData } from 'aws-amplify/storage';


export default function SimpleSlider(props) {

  const { pageName, fillType='cover' } = props
  const [pictureArray, pictureArraySet] = useState([{}])
  const context = useContext(UserContext)
  const [toBeDeleted, toBeDeletedSet] = useState()
  const [open, setOpen] = useState(false);
  const [uploadPhoto, uploadPhotoSet] = useState('')
  const [lengthOfPhoto, lengthOfPhotoSet] = useState(0)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    async function getPhotos() {
      try {
        const result = await list({
          prefix: `${pageName}/carousel/`,
          options: {
            listAll: true
          }
        });
        let x = []
        for (let item of result.items) {
          if (item.key === `${pageName}/carousel/`) continue
          x.push({ key: item.key })
        }
        pictureArraySet(x)
        lengthOfPhotoSet(x.length)
      } catch (error) {
        console.log(error);
      }
    }
    getPhotos()
  }, [])



  function deleteDialog(el) {
    toBeDeletedSet(el)
    handleClickOpen()
  }

  const handleCloseYes = async () => {

    try {
      await remove({ key: toBeDeleted.key }).then((e) => {
        setOpen(false);
        window.location.reload();
      })

    } catch (error) {
      console.log('Error ', error);
    }


  };

  const savePhoto = (async (e) => {
    try {
      const result = await uploadData({
        key: `${pageName}/carousel/${uploadPhoto.name}`,
        data: uploadPhoto
      }).result;
      window.location.reload();
    } catch (error) {
      console.log('Error : ', error);
    }

  })


  var settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: lengthOfPhoto >= 3 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    rows: 1,
    arrows: false,
  
  };

  return (
    <div>
      {context && (
        <div style={{ color: 'black' }}>
          <input type="file" accept="image/jpeg, image/png" onChange={(e) => { uploadPhotoSet(e.target.files[0]) }} />
          <button onClick={savePhoto}>Save</button>
        </div>
      )}
      <Slider {...settings}>
        {pictureArray.map(el => {
          return (
            <div>
                {context && (<TiDelete onClick={() => deleteDialog(el)} style={{ color: 'black', cursor: 'pointer', }} size={'5vmin'} color="black" />)}
              <div className="pictureCarDiv">
                <div style={{height: '100%'}}>
                <img style={{objectFit: fillType}} className="pictureCarImg" alt="carouselpicture" src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${el.key}`}></img>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>

      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this picture?"}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleCloseYes}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

    </div>
  );
}