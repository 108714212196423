import React, { useContext, useState, useEffect } from 'react'
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';



const UserContext = React.createContext()



const  UserContextProvider = ({ children }) => {
        // the value that will be given to the context
        const [user, setUser] = useState(false);
        const handleGuestSignIn = async () => {
            try {
              await fetchAuthSession();
            } catch (error) {
              console.error('Error signing in as guest:', error);
          };
        }
      
        // fetch a user from a fake backend API
        useEffect(() => {
          const fetchUser = async () => {
              try {
                  const cognitoUser = await getCurrentUser();
                  setUser(cognitoUser);

              } catch (err) {
                  console.error('No user');
              }
          };

       
      
          fetchUser();
          if(!user) handleGuestSignIn()
          
        }, []);
      
    return (
        <UserContext.Provider value={user}>
                {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserContextProvider };