import React, {useState, useRef, useEffect} from 'react'
import '../style/Faq.css'
import { Stack } from '@mui/material'
import FaqBullet from './FaqBullet'
import '../style/cssanimations.css'

export default function LogoFrontPage(props) {

    const {faqData, title= 'FAQs'} = props


    const [boxVisible1, setBoxVisible1] = useState(false);
    const boxRef1 = useRef()


    useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.id === "faqTitle") {
                    if (entry.isIntersecting) setBoxVisible1(true)
                }
            })
        })
        observer.observe(boxRef1.current)
    }, [])


    return (
        <Stack id="faq">
            <div ref={boxRef1} id="faqTitle" className= {boxVisible1 ? 'fadeInDownward' : 'restingPosition'}>
                {title}
            </div>

            {faqData.map((obj, i) => {
                return (
                    <FaqBullet headText={obj.headText} footText={obj.footText} id={i} boxVisible1={boxVisible1}></FaqBullet>
                )
            })}


        </Stack >


    )
}