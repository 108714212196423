import React, { useEffect, useState, useContext, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/PictureCarousel.css"
import { list } from 'aws-amplify/storage';
import { UserContext } from '../UserContext';
import { TiDelete } from "react-icons/ti";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { remove, uploadData } from 'aws-amplify/storage';


export default function AbbyTextPurple() {

    const [pictureArray, pictureArraySet] = useState([{}])
    const context = useContext(UserContext)
    const [toBeDeleted, toBeDeletedSet] = useState()
    const [open, setOpen] = useState(false);
    const [uploadPhoto, uploadPhotoSet] = useState('')
    const [boxVisible1, setBoxVisible1] = useState(false);
    const [boxVisible2, setBoxVisible2] = useState(false);
    const comRef = useRef()
    const comRef2 = useRef()


    useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.id === "comPartner") {
                    if (entry.isIntersecting) setBoxVisible1(true)
                }
                if (entry.target.id === "pictureArray") {
                    if (entry.isIntersecting) setBoxVisible2(true)
                }
            })
        })
        observer.observe(comRef2.current)
        observer.observe(comRef.current)
    }, [])


    const picstyle = { display: 'flex', margin: '1%', width: '30.9%', justifyContent: 'center'}
    const partnerPic = ((content, i) => {
        return (
            <div style={picstyle}>
                {context && (<TiDelete onClick={() => deleteDialog(content)} style={{ color: 'black', cursor: 'pointer', }} size={'5vmin'} color="black" />)}
                <img src={`https://abbyloupicturesonline173812-dev.s3.us-east-2.amazonaws.com/public/${content.key}`}
                    className={boxVisible2 ? "fadeInDownward" : 'restingPosition'}
                    style={{
                        animationDelay: `${(i * 150) + 1000}ms`,
                        width: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'contain',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} alt="partnerlogo">
                </img>
            </div>
        )
    })


    function deleteDialog(el) {
        toBeDeletedSet(el)
        handleClickOpen()
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseYes = async () => {

        try {
            await remove({ key: toBeDeleted.key }).then((e) => {
                setOpen(false);
                window.location.reload();
            })

        } catch (error) {
            console.log('Error ', error);
        }


    };



    const savePhoto = (async (e) => {
        try {
            const result = await uploadData({
                key: `aboutus/partners/${uploadPhoto.name}`,
                data: uploadPhoto
            }).result;
            window.location.reload();
        } catch (error) {
            console.log('Error : ', error);
        }

    })


    useEffect(() => {
        async function getPhotos() {
            try {
                const result = await list({
                    prefix: `aboutus/partners/`,
                    options: {
                        listAll: true
                    }
                });
                let x = []
                for (let item of result.items) {
                    if (item.key === `aboutus/partners/`) continue
                    x.push({ key: item.key })
                }
                pictureArraySet(x)
            } catch (error) {
                console.log(error);
            }
        }
        getPhotos()
    }, [])

    return (

        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center', margin: '5vmin 0 15vmin 0' }}>
            <div className={boxVisible1 ? "fadeInDownward" : 'restingPosition'} style={{ fontSize: '12vmin', width: '70%', color: 'black', borderRadius: '5vmin', fontWeight: '600', lineHeight: '90%', padding: '2vmin' }}>
                COMMUNITY <br /><span style={{ color: '#EA2225' }}>PARTNERS</span>
            </div>

            <div className={boxVisible1 ? "fadeInDownward" : 'restingPosition'} ref={comRef} id="comPartner" style={{ color: 'black', margin: '5vmin', textAlign: 'left', fontSize: '2.5vmin', lineHeight: '110%' }}>
                In addition to caring staff, we also have amazing community partners. Our partners offer time, talents, and other means. We are grateful for everything that they do in their support of us.
            </div>
            <div>
                <div className={boxVisible1 ? "fadeInDownward" : 'restingPosition'} style={{ fontSize: '3vmin', padding: '4vmin 0px 60px 0px', color: 'black', fontWeight: '800', }}>Below are a few of our community partners</div>
            </div>
            {context && (
                <div style={{ color: 'black' }}>
                    <input type="file" accept="image/jpeg" onChange={(e) => { uploadPhotoSet(e.target.files[0]) }} />
                    <button onClick={savePhoto}>Save</button>
                </div>
            )}
            <div ref={comRef2} id='pictureArray' style={{ display: 'flex', flexDirection: 'column', }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {pictureArray.map((content, i) => {
                        return partnerPic(content, i)
                    })}
                </div>


            </div>

            {context &&
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure you want to delete this picture?"}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={handleCloseYes}>Yes</Button>
                            <Button onClick={handleClose} autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            }


        </div>

    )
}
