import React, { useEffect, useRef } from 'react'
import { Stack, } from '@mui/material'
import LogoFrontPage from "../components/LogoFrontPage"
import PictureCarousel from "../components/PictureCarousel"
import Faqs from "../components/Faqs"
import WhoAreWe from "../components/WhoAreWe"
import titlePicture from '../pictures/titlepicture.jpg'
import IconHover from '../components/IconHover'
import '../style/LogoFrontPage.css'
import hostHomesSVG from '../pictures/svg/hostHomes.svg'
import dayProgramSVG from '../pictures/svg/dayProgram.svg'
import caledarSVG from '../pictures/svg/calendar.svg'
import gallerySVG from '../pictures/svg/GallerySVG.svg'




export default function HomePage() {


    const [smallScreen, setSmallScreen] = React.useState(false)
    const myRef = useRef()
    useEffect(() => {

        let sizeObserver = new ResizeObserver((entries) => {
            const x = entries[0]
            const isSmall = x.contentRect.width < 1050
            setSmallScreen(isSmall)
        })

        sizeObserver.observe(myRef.current)
    }, [smallScreen])

    const faqData = [
        {
            headText: 'What is our funding source for operating Abby Lou Services?',
            footText: ['We operate using DSPD services funding, which primarily comes through state and federal resources. Whenever feasible, DSPD delivers services utilizing a Medicaid home and community-based services waiver.',]
        },
        {
            headText: 'I have a family member or friend who would be interested in your program. Is Abby Lou Services available outside of St. George?',
            footText: ['Our headquarters are located in St George, and we proudly offer services to nearby areas including Ivins, Hildale, Toquerville, Cedar City, and Hurricane. As far as any additional locations, we are willing to provide services for more rural areas if the resources and quality of care can still meet or exceed our standards.',]
        },
        {
            headText: 'What specific disability must my family member have to be eligible to participate in Abby Lou Services?',
            footText: ['We provide assistance to individuals with intellectual and developmental disabilities, including, but not limited to, Down Syndrome, Cerebral Palsy, Spina Bifida, Autism Spectrum Disorder, Fragile X Syndrome, Prader-Willi, Intellectual Delay, and traumatic brain injury. Additionally, many of the individuals we support may also have concurrent medical and mental health issues such as Seizure Disorder, Pica, Diabetes, Mood Disorders, Schizoaffective Disorders, and Impulse Control Disorders.',]
        },
        {
            headText: 'How do I determine if Abby Lou is the right fit for my loved one or myself?',
            footText: ['We believe that just as individuals have unique preferences, beliefs, and aspirations, disability care should also be tailored to meet individual needs. There is no one-size-fits-all program, and at Abby Lou Services, we want you to make an informed decision about available care options. With that in mind, we invite you to visit our day program or host homes to see if Abby Lou Services is the right fit for you or your loved one.',]
        },
        {
            headText: 'I have come across two names associated with Abby Lou - Abby Lou Foundation and Abby Lou Services. Can you explain the distinction between the two?',
            footText: ['Abby Lou Foundation is committed to advocating for individuals with disabilities and improving quality of care within the community. The foundation provides legal support, grants, zero-interest loans, and education on DSPD laws and regulations. To learn more about the foundation or contribute to its mission, please visit abbylou.org.', 'Abby Lou Services LLC is dedicated to providing high-quality services to individuals who receive DSPD funding. Our focus is on creating meaningful and personalized opportunities that cater to the unique needs of those we support.'],
        },
    ]





    return (
        <div ref={myRef}>
            <Stack>
                <LogoFrontPage
                    logo={(
                        <div>
                            <div className='text' style={{ textAlign: 'left' }}>ABBY</div>
                            <div className='text' style={{ textAlign: 'left' }}>LOU</div>
                            <div className='text' style={{ textAlign: 'left' }}>SERVICES</div>
                        </div>
                    )}
                    sx={{ textAlign: 'left' }}
                    secondText={(<div>Experience the <span style={{ fontStyle: 'italic' }}>Abby Lou Effect</span></div>)}
                    pageName={'homepage'}
                />
                <div style={{marginBottom: '5%'}}>

                <PictureCarousel pageName={'homepage'}></PictureCarousel>
                </div>


                <WhoAreWe
                    littleText={<div> We are a service provider that is dedicated to putting quality of care above profits. It's one thing to say we care, but it's something completely different to live and practice what we believe.</div>}
                    bigText={<div>WHO <br></br>ARE <span style={{ color: '#682c84' }}>WE?</span></div>}
                    color={'#682c84'}
                    accentcolor={'#461d59'}
                    pageName={'homepage'}
                    imageName={'whoarewe'}
                    link={'about-us/#who-are-we'}
                    smallScreen = {smallScreen}
                ></WhoAreWe>

                <WhoAreWe
                    picture={titlePicture}
                    bigText={<div>WHAT <span style={{ color: '#0871B9' }}>DO </span><br></br>WE DO?</div>}
                    littleText={<div>We focus mainly on our residential services - where we use a host home model - and our 100% community-based day program. We also provide other services as needed.</div>}
                    color={'#0871B9'}
                    accentcolor={'#065f9c'}
                    reverse={true}
                    pageName={'homepage'}
                    imageName={'whatdowedo'}
                    link={'day-programs'}
                    smallScreen = {smallScreen}
                ></WhoAreWe>

                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: '0px 20vmin' }}>
                    <IconHover link={'host-homes'} svg={hostHomesSVG} color="#0871B9" text={<div>HOST<br></br>HOMES</div>}></IconHover>
                    <IconHover link={'day-programs'} svg={dayProgramSVG} color="#0871B9" text={<div>DAY<br></br>PROGRAM</div>}></IconHover>
                    <IconHover link={'calendar/#calendar-anchor'} svg={caledarSVG} color="#0871B9" text={<div>CALENDAR</div>}></IconHover>
                    <IconHover link={'gallery'} svg={gallerySVG} color="#0871B9" text={<div>GALLERY</div>}></IconHover>
                </div>

                <WhoAreWe
                    picture={titlePicture}
                    bigText={<div>LET US<br></br><span style={{ color: '#54AE49' }}>HELP</span></div>}
                    littleText={<div>Navigating the world of adult disability care can be hard. We've been there and we have some resources to share that will help you along your journey.</div>}
                    color={'#54AE49'}
                    accentcolor={'#3c8034'}
                    pageName={'homepage'}
                    imageName={'letushelp'}
                    link={'resources'}
                    smallScreen = {smallScreen}
                ></WhoAreWe>

                <Faqs faqData={faqData} />
            </Stack>
        </div>
    )
}
