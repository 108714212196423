import React from 'react'
import { Stack } from '@mui/material'
import LogoFrontPage from "../components/LogoFrontPage"
import TextPicPar from "../components/TextPicPar"
import Faqs from "../components/Faqs"
import titlePicture from "../pictures/titlepicture.jpg"
import sun from '../pictures/svg/Sun.png'
import UploadPicComponent from '../components/UploadPictureComponent'

import '../style/LogoFrontPage.css'

export default function DayPrograms() {

    const faqData = [
        {
            headText: "What sets Abby Lou day program apart from other day programs?",
            footText: ["Abby Lou Day Program Services operates on a fully community-based model. While other providers may also use the term \"community-based,\" Abby Lou truly embodies this concept. We do not have a dedicated building for our day program or office functions. Instead, we focus on engaging in enjoyable activities within our community. Our time together is spent participating in fun and meaningful experiences out in the vibrant community that we call home."],
        },
        {
            headText: "What is the staff-to-client ratio at Abby Lou Day Program Services?",
            footText: [
                "DSPD, the state agency responsible for regulating disability care providers, mandates a staffing ratio of 1:6, requiring one staff member for every six individuals served. However, at Abby Lou Day Program Services, we maintain a staffing ratio of 1:3-4, ensuring that each individual receives more personalized and high-quality care.",
                "Additionally, at Abby Lou, we prioritize an inclusive approach where individuals served and staff members are not separated. We consider ourselves a close-knit group of friends enjoying activities together in the community."
            ],
        },
        {
            headText: "How does Abby Lou Day Program Services accommodate individuals who do not enjoy the activities offered?",
            footText: ["At Abby Lou Day Program Services, we understand that not every activity will appeal to everyone. We strongly believe that individuals should have the autonomy to choose whether or not to participate in an activity. In such cases, while the individual accompanies the group to the planned activity location, they have the option to engage in alternative activities like reading a book, coloring, taking a walk in the vicinity, or simply observing their friends participating in the main activity. Our staff encourages individuals to explore and try new experiences but never imposes participation."],
        },
        {
            headText: "What are the operating hours of Abby Lou Day Program Services?",
            footText: ["Abby Lou Day Program Services typically operates Monday through Friday from 10 am to 4 pm for activities. However, certain community engagements may necessitate earlier or later pick-up and drop-off times. Any schedule adjustments are communicated in advance and followed to the best of our ability."],
        },
        {
            headText: "How does Abby Lou Day Program Services incorporate the goals of the individuals they serve into their program activities?",
            footText: [
                "Abby Lou Day Program Services integrates the goals of the individuals they serve into their activities by ensuring that all staff members receive annual training on the specific goals each individual is working towards. Daily documentation is also required to track the individual's progress and communicate this information to relevant parties involved in their care.",
                "Examples of goals include:",
                "Money Management: Real-life situations are used to teach money management skills, such as making purchases at locations like the Maverik gas station, shopping centers, and movie theaters, rather than just practicing with play money.",
                "Exercise: Physical activity is incorporated in a fun and engaging way, such as participating in activities like weekly kickboxing classes, basketball and volleyball sessions at Nets on Fire, hiking with friends, and cardio drumming at Shape Up Dixie.",
                "Social Skills: Social skills are developed through natural interactions, with staff modeling good social etiquette, facilitating friendships within the day program, and encouraging interactions with members of the community."

            ],
        }
    ]

    return (
        <Stack>
            <LogoFrontPage
                aligns="right"

                logo={(
                    <div>DAY PROGRAM</div>
                )}
                secondText={(
                    <div>You’re going to wish you could hang out with us</div>

                )}
                pageName={'dayprogram'}
            />
            <TextPicPar
                picture={titlePicture}
                littleText={<div><div style={{ paddingBottom: '2%' }}>We run a <span style={{ color: '#652C85', fontWeight: '600' }}>100% community-based</span> day program. This means that we don’t have a dedicated facility for our day program and all of our activities occur at host homes or in the community.</div> <div>We are a <span style={{ color: '#0871B9', fontWeight: '600' }}>unique program </span>. Most day programs are center-based with little community interaction but, not only do we get community interaction Monday through Friday, our model also provides exposure for members of the community and learning opportunities for everyone.</div></div>}
                bigText={<div>WE <span style={{ color: '#54AE49' }}>GET</span><br></br>OUT<br /></div>}
                color={'#54AE49'}
                pageName={'dayprogram'}
                imageName={'wegetout'}
            >
            </TextPicPar>
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black', maxWidth: '1903px' }}>
                    <div style={{ fontWeight: '600' }}>Check out some of what we do regularly</div>
                    <img src={sun} alt="funPic" style={{ width: '13vmin', margin: '6vmin' }}></img>

                    <div style={{ marginBottom: '8vmin', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <div style={{ fontWeight: '600', fontSize: '12vmin' }}>
                            ACTIVITIES
                        </div>
                        <div style={{ width: '80%', height: '90%', display: 'flex', flexDirection: 'column' }}>
                            <img alt="activities" src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/dayprogram/activities.jpg`} style={{ objectFit: 'cover', width: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderRadius: '2vmin', }}></img>
                            <UploadPicComponent pageName={'dayprogram'} imageName={'activities'}></UploadPicComponent>
                        </div>
                    </div>

                    <div style={{ marginBottom: '8vmin', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ fontWeight: '600', fontSize: '12vmin' }}>
                            EVENTS
                        </div>
                        <div style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <img alt="events" src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/dayprogram/events.jpg`} style={{ objectFit: 'cover', width: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderRadius: '2vmin', }}></img>
                            <UploadPicComponent pageName={'dayprogram'} imageName={'events'}></UploadPicComponent>
                        </div>
                    </div>

                    <div style={{ marginBottom: '8vmin', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ fontWeight: '600', fontSize: '12vmin' }}>
                            TRIPS
                        </div>
                        <div style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <img alt="trips" src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/dayprogram/trips.jpg`} style={{ objectFit: 'cover', width: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderRadius: '2vmin', }}></img>
                            <UploadPicComponent pageName={'dayprogram'} imageName={'trips'}></UploadPicComponent>
                        </div>
                    </div>
                </div>
            </div>


            <Faqs faqData={faqData} />
        </Stack>
    )
}
