import React, { useEffect, useState, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/PictureCarousel.css"
import { list } from 'aws-amplify/storage';
import { UserContext } from '../UserContext';
import { TiDelete } from "react-icons/ti";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { remove, uploadData } from 'aws-amplify/storage';


export default function AbbyTextPurple() {

    const [pictureArray, pictureArraySet] = useState([{}])
    const context = useContext(UserContext)
    const [toBeDeleted, toBeDeletedSet] = useState()
    const [open, setOpen] = useState(false);
    const [uploadPhoto, uploadPhotoSet] = useState('')


    const picstyle = { margin: '1%', width: '30.9%' }
    const partnerPic = ((content) => {
        return (
            <div style={picstyle}>
                {context && (<TiDelete onClick={() => deleteDialog(content)} style={{ color: 'black', cursor: 'pointer', }} size={'5vmin'} color="black" />)}
                <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${content.key}`}
                    style={{
                        width: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                    }} alt="partnerlogo">
                </img>
            </div>
        )
    })


    function deleteDialog(el) {
        toBeDeletedSet(el)
        handleClickOpen()
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseYes = async () => {

        try {
            await remove({ key: toBeDeleted.key }).then((e) => {
                setOpen(false);
                window.location.reload();
            })

        } catch (error) {
            console.log('Error ', error);
        }


    };



    const savePhoto = (async (e) => {
        try {
            const result = await uploadData({
                key: `gallery/pictures/${uploadPhoto.name}`,
                data: uploadPhoto
            }).result;
            window.location.reload();
        } catch (error) {
            console.log('Error : ', error);
        }

    })


    useEffect(() => {
        async function getPhotos() {
            try {
                const result = await list({
                    prefix: `gallery/pictures/`,
                    options: {
                        listAll: true
                    }
                });
                let x = []
                for (let item of result.items) {
                    if (item.key === `gallery/pictures/`) continue
                    x.push({ key: item.key })
                }
                pictureArraySet(x)
            } catch (error) {
                console.log(error);
            }
        }
        getPhotos()
    }, [])

    return (

        <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center', margin: '5vmin 0 15vmin 0' }}>
            <div style={{ fontSize: '12vmin', width: '70%', color: 'black', borderRadius: '5vmin', fontWeight: '600', lineHeight: '90%', padding: '2vmin' }}>
                GALLERY
            </div>

            {context && (
                <div style={{ color: 'black' }}>
                    <input type="file" accept="image/jpeg, image/png" onChange={(e) => { uploadPhotoSet(e.target.files[0]) }} />
                    <button onClick={savePhoto}>Save</button>
                </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {pictureArray.map((content, i) => {
                        return partnerPic(content)
                    })}
                </div>


            </div>

            {context &&
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure you want to delete this picture?"}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={handleCloseYes}>Yes</Button>
                            <Button onClick={handleClose} autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            }


        </div>

    )
}