import React, { useState } from 'react'
import HouseSVG from '../pictures/svg/HouseSVG.svg'
import '../style/Benefits.css'
import benefitsTree from '../pictures/benefitsicon/benefitsTree.png'
import family from '../pictures/benefitsicon/benefitsFamily.png'
import scale from '../pictures/benefitsicon/benefitsScale.png'
import strong from '../pictures/benefitsicon/benfitsStrong.png'

export default function Benefits() {

    const [purpleOpen, setPurpleOpen] = useState(true)
    const [redOpen, setRedOpen] = useState(true)
    const [greenOpen, setGreenOpen] = useState(true)
    const [blueOpen, setBlueOpen] = useState(true)

    const purpleHandlerOpen = (() => {setPurpleOpen(false)})
    const purpleHandlerClose = (() => {setPurpleOpen(true)})

    const redHandlerOpen = (() => {setRedOpen(false)})
    const redHandlerClose = (() => {setRedOpen(true)})
    
    const greenHandlerOpen = (()=> {setGreenOpen(false)})
    const greenHandlerClose = (()=> {setGreenOpen(true)})

    const blueHandlerOpen = (()=> {setBlueOpen(false)})
    const blueHandlerClose = (()=> {setBlueOpen(true)})


    const twoBubbles = ((props) => {
        const {
            text,
            color = 'purple',
            rotate = 0,
            bottomSmall = '0vmin',
            bottom,
            left,
        } = props

        return (
            <div>
                <div style={{ display: 'inline', }}>
                    <div style={{
                        position: 'relative',
                        backgroundColor: color,
                        width: '17vmin',
                        height: '17vmin',
                        bottom: bottom,
                        left: left,
                        borderRadius: '100%',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: '2vmin',
                        

                    }}>
                        {text}
                    </div>
                    <div style={{ position: 'relative', width: '5vmin', height: '5vmin', backgroundColor: color, borderRadius: '100%', bottom: `calc(${bottom} + 22vmin - ${bottomSmall})`, left: '18vmin' }}></div>
                </div>
            </div>
        )
    })




    return (
        <div style={{ display: 'flex', flexDirection: 'column', color: 'black', alignItems: 'center', marginBottom: '10vmin' }}>
            <div style={{ fontSize: '10vmin', fontWeight: '600', lineHeight: '100%', padding: '3%'}}>
                HOST <span style={{color: '#54AE49'}}>HOMES</span><br></br>BENEFITS
            </div>
            <div style={{ height: '85vmin', width: '85vmin', display: 'inline-block', overflow: 'hidden' }}>
                <div style={{ width: '15vmin', height: '15vmin', backgroundColor: 'white', position: 'relative', borderRadius: '100%', top: '41.5%', left: '41%', zIndex: 4, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <img draggable='false' src={HouseSVG} style={{ width: '10vmin' }} ></img>
                </div>

                <div  onMouseEnter={purpleHandlerOpen} onMouseLeave={purpleHandlerClose} style={{ width: '49.5%', height: '49.5%', top: '32.9%', position: 'relative', borderRadius: '2vmin', overflow: 'hidden' }}>
                    <div className={purpleOpen ? "purpleIn" : "purpleOut"} style={{ zIndex: '1', cursor: 'pointer', width: '200%', height: '200%', position: 'relative', backgroundColor: '#652C85', top: '-42vmin', }}></div>
                    {twoBubbles({ text: (<div>Safety and independence <b>balance</b></div>), color: '#652C85', bottom: '59.2vmin',  bottomSmall: '0vmin' })}
                    <div onMouseEnter={purpleHandlerOpen} className={purpleOpen ? "textOutx" : "textInx"} style={{ cursor: 'pointer', color: 'white', position: 'relative', zIndex: '2', bottom: '94vmin', margin: '5%', fontSize: '2.5vmin', fontWeight: '300' }}>
                        The right <b>balance</b> of independence, expectations, and safety
                    </div>
                    <img src={scale} style={{position: 'relative', bottom: '115.1vmin', left: '11vmin', zIndex: 3, width: '7vmin'}}></img>
                </div>

                <div onMouseEnter={redHandlerOpen} onMouseLeave={redHandlerClose} style={{ width: '49.5%', height: '49.5%',  top: '-67.1%', position: 'relative', borderRadius: '2vmin', overflow: 'hidden', }}>
                    <div className={redOpen ? "purpleIn" : "purpleOut"} style={{ zIndex: '1', cursor: 'pointer', width: '200%', height: '200%', position: 'relative', backgroundColor: '#EA2225', top: '1vmin' }}></div>
                    {twoBubbles({ text: (<div ><b>Natural</b> Community Engagement</div>), color: '#EA2225', bottom: '84.1vmin', bottomSmall: '21vmin'})}
                    <div onMouseEnter={redHandlerOpen} className={redOpen ? "textOutx" : "textInx"} style={{ cursor: 'pointer', color: 'white', position: 'relative', zIndex: '2', bottom: '94vmin', margin: '5%', fontSize: '2.5vmin', fontWeight: '300' }}>
                        They provide opportunities for <b>natural</b> community engagement.
                    </div>
                    <img src={benefitsTree} style={{position: 'relative', bottom: '86.1vmin', left: '11vmin', zIndex: 3, width: '8vmin'}}></img>
                </div>

                <div onMouseEnter={blueHandlerOpen} onMouseLeave={blueHandlerClose} style={{ width: '49.5%', height: '49.5%',  top: '-116.5%', left: '42.9vmin', position: 'relative', borderRadius: '2vmin', overflow: 'hidden', }}>
                    <div className={blueOpen ? "purpleIn" : "purpleOut"} style={{ zIndex: '1', cursor: 'pointer', width: '200%', height: '200%', position: 'relative', backgroundColor: '#0871B9', top: '.9vmin', right: '42.9vmin' }}></div>
                    {twoBubbles({ text: (<div>A <b>Family</b> Feel</div>), color: '#0871B9', bottom: '84.1vmin', bottomSmall: '21vmin', left: '25vmin' })}
                    <div onMouseEnter={blueHandlerOpen} className={blueOpen ? "textOutx" : "textInx"} style={{ cursor: 'pointer', color: 'white', position: 'relative', zIndex: '2', bottom: '94vmin', margin: '5%', fontSize: '2.5vmin', fontWeight: '300' }}>
                        A <b>family</b> dynamic naturally develops among all involved
                    </div>
                    <img src={family} style={{position: 'relative', bottom: '85.1vmin', left: '-11vmin', zIndex: 3, width: '8vmin'}}></img>
                </div>

                <div onMouseEnter={greenHandlerOpen} onMouseLeave={greenHandlerClose} style={{ width: '49.5%', height: '49.5%',  top: '-115.6%', left: '42.9vmin', position: 'relative', borderRadius: '2vmin', overflow: 'hidden', }}>
                    <div className={greenOpen ? "purpleIn" : "purpleOut"} style={{ zIndex: '1', cursor: 'pointer', width: '200%', height: '200%', position: 'relative', backgroundColor: '#54AE49', top: '-42vmin', right: '42.9vmin' }}></div>
                    {twoBubbles({ text: (<div>A <b>strong</b>, stable environment</div>), color: '#54AE49', bottom: '59.2vmin', bottomSmall: '0vmin', left: '25vmin' })}
                    <div onMouseEnter={greenHandlerOpen} className={greenOpen ? "textOutx" : "textInx"} style={{ cursor: 'pointer', color: 'white', position: 'relative', zIndex: '2', bottom: '94vmin', margin: '5%', fontSize: '2.5vmin', fontWeight: '300' }}>
                        They provide a <b>strong</b> and stable environment for personal growth
                    </div>
                    <img src={strong} style={{position: 'relative', bottom: '109.1vmin', left: '-11vmin', zIndex: 3, width: '8vmin'}}></img>
                </div>



            </div>

        </div>
    )
}