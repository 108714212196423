import React, { useState, useEffect, useRef } from 'react'
import MyButton from '../components/MyButton'
import UploadPicComponent from './UploadPictureComponent'
import { NavLink } from 'react-router-dom'
import '../style/cssanimations.css'
export default function LogoFrontPage(props) {
    const {
        bigText,
        littleText,
        color,
        accentcolor = '#8036a3',
        reverse = false,
        imageName,
        pageName,
        link,
        smallScreen = false
    } = props



    const [boxVisible1, setBoxVisible1] = useState(false);
    const boxRef1 = useRef()


    useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.id === "image1") {
                    if (entry.isIntersecting) setBoxVisible1(true)
                }
            })
        })
        observer.observe(boxRef1.current)
    }, [])



    return (
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>

        <div style={{ display: 'flex', flexDirection: 'column', margin: '4%', marginTop: '10vmin', marginBottom: '10vmin', maxWidth: '1750px' }}>
            <div style={{ display: 'flex', flexDirection: (smallScreen ? 'column': 'row'), }}>
                {!reverse && <div ref={boxRef1} id="image1" className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{ width: (smallScreen ? '100%': '50%'), marginLeft: '', }}>
                    <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${pageName}/${imageName}.jpg`} style={{
                        animationDelay: '300ms',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4vmin',
                    }}
                        alt="whoarewepic"
                    ></img>
                    <UploadPicComponent pageName={pageName} imageName={imageName}></UploadPicComponent>
                </div>}

                <div id="bigtext3" style={{ width: (smallScreen ? '100%': '50%'), marginLeft: (smallScreen ? '' : '2vmin') }}>
                    <div className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{
                        animationDelay: '800ms',
                        lineHeight: '95%',
                        color: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: reverse ? 'right' : 'left',
                        justifyContent: 'center',
                        fontSize: '12vmin',
                        fontWeight: '700',
                        borderRadius: '4vmin'
                    }}>
                        {bigText}
                    </div>

                    <div id="littletext2" className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{ animationDelay: '1300ms', textAlign: reverse ? 'right' : 'left', color: 'black', fontSize: '2.5vmin', lineHeight: '110%' }}>
                        {littleText}
                    </div>
                    <div className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{ display: 'flex', animationDelay: '1300ms', alignItems: 'start', justifyContent: reverse ? 'right' : 'left', padding: '2% 0% 0% 0%' }}>
                        <NavLink to={`/${link}`} onClick={(() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'

                            })
                        })}><MyButton text={'Learn More'} textcolor={'white'} hoverColor={accentcolor} color={color} mainColor={color}></MyButton></NavLink>
                    </div>
                </div>

                {reverse && <div id="image1" ref={boxRef1} className={boxVisible1 ? 'fadeInDownward' : 'restingPosition'} style={{width: (smallScreen ? '100%': '50%'), marginLeft: '1%', marginTop: '2%', color: 'black' }}>
                    <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/public/${pageName}/${imageName}.jpg`} style={{
                        animationDelay: '300ms',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4vmin'
                    }}
                        alt="whoarewepic"
                    ></img>
                    <UploadPicComponent pageName={pageName} imageName={imageName}></UploadPicComponent>
                </div>}
            </div>

        </div >
        </div>
    )
}