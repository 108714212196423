import React, { useState } from 'react'
import '../style/Faq.css'
import downArrow from '../pictures/downArrow.png'
import '../style/Information.css'
import '../style/cssanimations.css'
export default function FaqBullet(props) {

    const { headText, footText, id, boxVisible1} = props
    const [showContent, setShowContent] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);

    const toggleSpin = () => {
        setIsSpinning(!isSpinning);
    };

    const toggleContent = () => {
        if (!showContent) {
            setContentHeight(document.getElementById(id).scrollHeight);
        } else {
            setContentHeight(0);
        }
        setShowContent(!showContent);
        toggleSpin()
    };


    return (

        <ul className={boxVisible1 ? 'fadeInDownward': 'restingPosition'} style={{animationDelay: (`${(id * 150) + 600}ms`)}}>
            <lh>
                <div onClick={toggleContent} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'start', paddingBottom: '2vmin' }}>
                    <div>
                        <img draggable='false' className={isSpinning ? 'upspin spin' : 'upspin'} src={downArrow} style={{ width: '2vmin', height: '2vmin', marginRight: '5vmin' }}></img>
                    </div>
                    <b>{headText}</b>
                </div>

            </lh>
            <li id={id} className="content" style={{ height: contentHeight, listStyleType: 'none' }}>
                {footText.map((content, i) => {
                    return (
                        <div key={i} style={{ color: 'black', borderRadius: '2vmin', padding: '2vmin' }}>
                            {content}
                        </div>
                    )
                })}

            </li>
        </ul>
    )
}