import React, {useState} from 'react'
import '../style/WhoAreWe.css'



export default function MyButton(props) {
    const [hover, setHover] = useState(false)

    function hoverHelperEnter() {
        setHover(true)
    }

    function hoverHelperExit() {
        setHover(false)
    }
    
    const {
        text,
        textcolor,
        hoverColor = '#ededed',
        mainColor = '#FDFDFD',
    } = props

    return (
        <button  className='buttonFade' onMouseEnter={hoverHelperEnter} onMouseLeave={hoverHelperExit} style={{backgroundColor: hover ? hoverColor : mainColor, borderRadius: '1000px', borderStyle: 'none', padding: '8px 30px', fontSize: '20px', color: textcolor, cursor: 'pointer' }}>
            {text}
        </button >
    )
}