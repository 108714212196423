import React from 'react'
import { Stack } from '@mui/material'
import '../style/Calendar.css'
import LogoFrontPage from '../components/LogoFrontPage'
import PictureGallery from '../components/PictureGallery'


export default function Gallery() {


    return (
        <Stack>
            <LogoFrontPage
                logo={(
                    <div style={{ textAlign: 'center' }}>
                        <div className='text'>GALLERY</div>
                    </div>
                )}
                secondText={(<div>Pictures or it didn't happen</div>)}
                aligns='left'
                pageName={'gallery'}
            />
            
            <PictureGallery>

            </PictureGallery>
        </Stack>
    )
}
